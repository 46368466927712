.a{
  width:100% ;
  /* background-image: url("../images/ba.gif"); */
}
.lb{
  width: 1900px;
  height: 875px;
  overflow: hidden;
  margin: auto;
}

.ant-carousel .slick-track{
    height: 800px;
}

/* 总 */
.middle{
  width: 1280px;
  height: 2550px;
  margin: auto;

}
/*  */
.home-div{
  width: 100%;
  height: 2000px;
}
.home-left{
  width: 100%;
  height: 550px;
  float: left;
  background-color: mintcream;
}
.home-middle{
  width: 100%;
  height: 500px;
  float: left;
}
.home-right{
  width: 100%;
  height: 1250px;
  float: left;
  margin-top: 170px;
  background-color: mintcream;
  overflow: hidden;
}
/* 简介css */
/*   text-align: center 使图片线居中*/
.home-left-account{
  width: 100%;
  height: 150px;
  text-align: center;
  margin-top: 6px;
  margin-bottom:5px;
  background-color: mintcream;
}
.home-left-info{
  width: 600px;
  height: 350px;
  line-height: 30px;
  text-indent:2em;
  float: left;
  margin:30px 0px 0px 50px;
}
.home-left-contact{
  width: 600px;
  height: 350px;
  float: left;
  margin:30px 0px 0px 20px;
}
.home_aboutus_info p {
  line-height: 30px;
  overflow: hidden;
  text-indent:2em;
  }
.ant-btn-primary {
  width: 100px;
  height: 50px;
   color: hsl(0, 33%, 93%);
  }

  .home-middle-steward{
      width: 1280px;
      height: 500px;
      background-color: mintcream;
  }
  /*  margin: 往下，往上，往左，往右 移动 */
  .home-middle-steward-img{
      width: 600px;
      height: 350px;
      margin:10px 0px 0px 10px ;
      float: left;
  }
  .home-middle-info{
    width: 600px;
    height: 350px;
    float: left;
    margin:30px 0px 0px 50px;
    text-indent:2em;
  }
  
  .home-middle-steward-foot{
    width: 100%;
    height: 90px;
    float: left;
  }

  .home-middle-steward-foot div{
    width: 150px;
    height: 50px;
    float: left;
    margin:20px 0px 0px 60px;
  }
  .home-right-main{
      width: 360px;
      height: 500px;
      overflow: hidden;
      float: left;
      margin: 20px 20px 0px 38px;
      background-color: rgb(243, 233, 231);
      text-align: center;
  }

  .home-right-main img:hover{
   transition: all 0.6s;
   transform: scale(1.4);
 }

  .home-right-main-img{
    width: 200px;
    height: 200px;
    margin: auto;
  }
.home-right-main-text{
  width: 360px;
  height: 220px;

}
.Home-div-fy{
width: 1280px;
height: 50px;
float: left;
padding-left: 40px;
background-color:  mintcream;


}