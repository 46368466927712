.Contact-div{
    width: 1280px;
    height: 1050px;
    margin: auto;
}

.Contact-div-top{
    width: 100%;
    height: 400px;
    margin-top: 60px;
}
.Contact-div-top div{
    width: 400px;
    height: 400px;
    margin-left: 20px;
    background-color: indigo;
    float: left;
}

.Contact-div-info{
    width: 1280px;
    height: 270px;

}
.Contact-div-info div{
    width: 230px;
    height: 256px;
    text-align: center;
    float: left;
    margin: 0px 0px 0px 20px;
    background-color: rgb(248, 248, 248);
}
.Contact-div-info-phone .anticon svg {
    display: inline-block;
    height: 115px;
    width: 50px;
}
.Contact-div-info-fox .anticon svg {
    display: inline-block;
    height: 115px;
    width: 50px;
}
.Contact-div-info-code .anticon svg {
    display: inline-block;
    height: 115px;
    width: 50px;
}
.Contact-div-info-web .anticon svg {
    display: inline-block;
    height: 115px;
    width: 50px;
}
.Contact-div-info-email .anticon svg {
    display: inline-block;
    height: 115px;
    width: 50px;
}
