.Hot-div{
width: 1280PX;
height: 1850PX;
}
.Hot-div-headline{
    width: 1280px;
    height: 700px;
    float: left;
}
.Hot-div-headline-one{
    width: 600px;
    height:700px;
    float: left;
    margin: 0px 0px 0px 20px;
    border:1px solid blanchedalmond;
    background-color: rgb(248, 248, 248);

}
.Hot-div-headline-one-img{
    width: 600px;
    height: 500px;
}
.Hot-div-headline-one-img img:hover{
    transform: scale(0.98);
}

.Hot-div-headline-one-text{
    text-align: center;
}
.Hot-div-headline-one-text-top{
    height: 80px;
    margin: 20px 0px 0px 0px;   
    padding: 10px 10px 0px 10px;

}
.Hot-div-headline-one-text-top a{
   font-size: 15px;
   color:  rgb(105, 101, 104);
}
.Hot-div-headline-one-time-padding{
    padding-left: 400px;
}
.Hot-div-headline-one-time-span{
    padding-left: 30px;
    font-size: 20px;
}

.Hot-div-search{
    width: 1280px;
    height: 90px;
    float: left;
}
.Hot-div-search-span{
    float: right;
    padding: 20px 

}
                  
.Hot-div-hotList{
    width: 1280px;
    height: 1000px;
    float: left;
   
}

.Hot-div-hotList-list{
    width: 1280px;
    height: 300px;
    float: left;
    margin: 20px 0px 0px 0px;
    border:1px solid rgb(250, 230, 230);
    background-color: rgb(248, 248, 248);
}
.Hot-div-hotList-list-time-span{
    float: right;
    padding: 50px ;
    font-size: 20px;
}
.Hot-div-hotList-list-headline-span{
    padding: 20px ;
    font-size: 20px;
    color:  rgb(105, 101, 104);

}
.Hot-div-hotList-list-img{ 
    width: 200px;
    height: 200px;
    float: left;
    margin: 50px 0px 0px 20px;
}
.Hot-div-hotList-list-headline{
    width: 800px;
    height: 200px;
    float: left;
    margin: 50px 0px 0px 20px;
    color:  rgb(105, 101, 104);

}
.Hot-div-hotList-fy{
    width: 1280px;
    height: 50px;
    margin: auto;
    float: left;
}