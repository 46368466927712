.footss-div{
    width:1280px; 
    height: 350px;
    margin: 30px 0;
    overflow: hidden;
   background-color: rgb(230, 236, 236);
}
.footss-div-t{
    width: 100%;
    height: 90px;
    margin: 10px 0px 0px 0px;
    text-align: center;
    float: left;
}
.footss-div-z{
    width: 800px;
    height: 200px;
    margin: 20px 0px 0px 30px;
    float: left;
}
.footss-div-z-info-yw-span1{
        font-size: 20px;
        color: rgb(62, 63, 65);
}
.footss-div-z-info-yw-span2{
    padding-left: 100px;
}
.footss-div-z-info-yw-span3{
    font-size: 20px;
    color: rgb(62, 63, 65);
    
}
.footss-div-z-dz{
    margin: 20px 0px 0px 0px;
}
.footss-div-z-dz .anticon svg{
    width: 30px;
    height: 30px;
}
.footss-div-z-dz span{
    font-size: 20px;
    color: rgb(62, 63, 65);
    padding-left: 30px;
    padding-top: 20px;
}
.footss-div-i{
    width: 150px;
    height: 200px;
    float: left;
    margin:40px 0px 0px 50px ;
}