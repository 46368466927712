.new-div{
    width: 1280px;
    height: 2470px;
    margin: auto;
}
.new-div-hander{
    width: 100%;
    height: 50px;

}
.Hot-div-img{
    width: 1280px;
    height: 560px;
}