
 .Consult-div{
    width: 1280px;
    height:2700px;
    margin: auto;
    
}
.Consult-left-account{
    width:100% ;
    height: 200px;
    background-image: url(../images/consult/fabj.png);
}

.Consult-div-hander{
    width: 1100px;
    height: 60px;
    margin: 50px 0 0 150px;
}
.Consult-div-top{
    width: 780px;
    height: 450px;
    float: left;
}
.Consult-div-left{
    height: 100%;
    width: 200px;
    float: left;
    background-color:#f8f6f6;
}
.Consult-div-left ul{
    background-color:#f8f6f6 ;
}

.Consult-div-right{
    height: 100%;
    width: 780px;
    float: left;
    background-color:#f8f6f6;
}
.Consult-div-right img{
  width: 300px;
  height: 213px;
}
.Consult-div-right-zxp{
    width: 380px;
    height: 350px;
    float: left;
    margin: 40px 0 0 20px;
}
.Consult-div-right-zxp p{
    font-size:14px;
    text-align: justify;
    font-family: "fontface__思源黑体Medium__18__1105926778742";
    text-indent: 2em;
    line-height: 40px;
}
.Consult-div-right-zximg{
    width: 300px;
    height: 350px;
    float: left;
    margin: 150px 0 0 50px;

}

.Consult-div-right-foot{
    width: 780px;
    height: 400px;

}
.Consult-div-right-foot-left{
    height: 400px;
    width: 210px;
    float: left;

}
.Consult-div-right-foot-left img{
    height: 265px;
    width: 200px;
    margin: 25px 0px 0px 0px;
}
.Consult-div-right-foot-left p{
  font-size:15px;
  text-align: center;
  color:#0a0a0a;
  font-family: "新宋体";
}
.Consult-div-right-foot-list{

    width: 570px;
    height: 400px;
    float: left;
}
.ant-spin-container .anticon svg {
    width: 30px;
    height: 30px;
}