.Recruit-div{
    width: 1280px;
    height: 2000px;
    margin: auto;
}
.Recruit-div-img{
    width: 1280px;
    height: 500px;
}
.Recruit-div-table{
    width: 1280px;
    height: 1300px;
    padding: 20px 0px 0px 30px;
    background-color: rgb(248, 248, 248);
}